<template>
  <div class="tutorial">
    <h1>{{ $t('tutorial.howToPlay') }}</h1>
    <!-- <video src="https://cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/video.mp4" controls /> -->
    <template v-if="showVideo">
      <video
        v-if="isFrench"
        src="@/assets/ford/M2674_FR_FordBroncoEggHunt_011624_TP.mp4"
        autoplay
        muted
        controls
        playsinline
        @ended="scrollToButton"
      />
      <video
        v-else
        src="@/assets/ford/M2674_EN_FordBroncoEggHunt_011624_TP.mp4"
        autoplay
        muted
        controls
        playsinline
        @ended="scrollToButton"
      />
    </template>
    <!-- <p>Nulla rutrum est lacus, eget accumsa naugue fringilla in. Etiam at cond imentum neque, efficitur elementum.</p> -->
    <PassportButton ref="btn" to="/home" :label="$t('tutorial.startEggHunt')" />
  </div>
</template>

<script>
import PassportButton from '../components/PassportButton.vue';

export default {
  components: { PassportButton },
  data() {
    return {
      showVideo: true,
    };
  },
  computed: {
    isFrench() {
      return this.$store.state.locale === 'fr';
    },
  },
  methods: {
    scrollToButton() {
      this.$refs.btn.$el.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss" scoped>
.tutorial {
  // padding: 58px 36px;
  // h1 {
  //   margin-top: 52px;
  //   margin-bottom: 44px;
  // }
  video {
    max-width: 100%;
    margin-bottom: 36px;
  }
  // p {
  //   margin-top: 44px;
  // }
}
</style>
